import {Page, Paging} from "@variocube/app-ui";
import {Api} from "../Api";
import {
	AppTenant,
	ChangePasswordRequest,
	CreateUserRequest,
	GuessUserTypeRequest,
	GuessUserTypeResponse,
	NotificationTemplate,
	Tenant,
	TenantOAuth2,
	TenantUpdateSettingsRequest,
	User,
} from "./TenantUser";

export class TenantUserProvider {
	static guessUserType(username: string): Promise<GuessUserTypeResponse> {
		const request: GuessUserTypeRequest = {
			username,
		};
		return Api.POST("public/users/guess-user-type", request);
	}

	static me(tenantId: string): Promise<User> {
		return Api.GET(`tenants/${tenantId}/users/current-user`).then((response) => {
			return response as User;
		});
	}

	static refreshMyAccessCode(tenantId: string): Promise<User> {
		return Api.POST(`tenants/${tenantId}/users/current-user-refresh-access-code`, {}).then((response) => {
			return response as User;
		});
	}

	static changeMyPassword(tenantId: string, currentPassword: string, nextPassword: string): Promise<User> {
		const request: ChangePasswordRequest = {
			currentPassword,
			nextPassword,
		};
		return Api.POST(`tenants/${tenantId}/users/current-user/change-password`, request).then((response) => {
			return response as User;
		});
	}

	static changeUserPassword(tenantId: string, username: string, nextPassword: string): Promise<User> {
		return Api.POSTstring(`tenants/${tenantId}/users/change-password/${encodeURIComponent(username)}`, nextPassword)
			.then(
				(response) => {
					return response as User;
				},
			);
	}

	static listTenantUsers(tenantId: string): Promise<User[]> {
		return Api.GET(`tenants/${tenantId}/users`).then((response) => {
			return response as User[];
		});
	}

	static modifyQueryString(paging: Paging) {
		let queryString = new URLSearchParams(paging.toQueryString());
		var sortProp = queryString.get("sort")?.split(",");
		if (sortProp && sortProp[0] == "fullname") {
			sortProp[0] = "first_name";
			queryString.set("sort", `${sortProp[0]},${sortProp[1]}`);
			queryString.append("sort", `last_name,${sortProp[1]}`);
		}
		return queryString;
	}

	static listTenantUsersByPage(tenantId: string, paging: Paging): Promise<Page<User>> {
		let queryString = TenantUserProvider.modifyQueryString(paging);
		return Api.GET(`tenants/${tenantId}/users-by-page?${queryString}`, {
			Accept: "application/vnd.deliveries.paged+json",
		})
			.then((response) => {
				console.log(response, "response");
				return response as Page<User>;
			});
	}

	static search(tenantId: string, search: string, paging: Paging): Promise<Page<User>> {
		let queryString = TenantUserProvider.modifyQueryString(paging);

		var sortProp = queryString.get("sort")?.split(",");
		if (sortProp && sortProp[0] == "fullname") {
			sortProp[0] = "first_name";
			queryString.set("sort", `${sortProp[0]},${sortProp[1]}`);
			queryString.append("sort", `last_name,${sortProp[1]}`);
		}

		let path = `tenants/${tenantId}/users/search`;
		if (search) path += "/" + search;
		return Api.GET(`${path}?${queryString}`, {
			Accept: "application/vnd.deliveries.paged+json",
		})
			.then((response) => {
				return response as Page<User>;
			});
	}

	static get(tenantId: string, username: string): Promise<User> {
		return Api.GET(`tenants/${tenantId}/users/${encodeURIComponent(username)}`).then((response) => {
			return response as User;
		});
	}

	static put(tenantId: string, username: string, request: CreateUserRequest): Promise<User> {
		return Api.PUT(`tenants/${tenantId}/users/${encodeURIComponent(username)}`, request).then((response) => {
			return response as User;
		});
	}

	static async delete(tenantId: string, username: string) {
		await Api.DELETE(`tenants/${tenantId}/users/${encodeURIComponent(username)}`);
	}

	static create(tenantId: string, request: CreateUserRequest): Promise<User> {
		return Api.POST(`tenants/${tenantId}/users`, request).then((response) => {
			return response as User;
		});
	}

	static updateSettings(tenantId: string, request: TenantUpdateSettingsRequest): Promise<Tenant> {
		return Api.POST(`tenants/${tenantId}/settings`, request).then((response) => {
			return response as Tenant;
		});
	}

	static updateOAuth2Settings(tenantId: string, mutation: TenantOAuth2) {
		return Api.PUT(`tenants/${tenantId}/settings/oauth2`, mutation).then((response) => {
			return response as TenantOAuth2;
		});
	}

	static async removeOAuth2Settings(tenantId: string) {
		await Api.DELETE(`tenants/${tenantId}/settings/oauth2`);
	}

	static listNotificationTemplates(centerId: string) {
		return Api.GET(`tenants/${centerId}/settings/templates`).then(r => r as NotificationTemplate[]);
	}

	static createTenant(tenantId: string): Promise<Tenant> {
		return Api.POST(`tenants/${tenantId}/create`, {}).then((response) => {
			return response as Tenant;
		});
	}

	static purgeRecipients(tenantId: string, allSources: boolean) {
		return Api.DELETE(`/tenants/${tenantId}/purge-recipients?allSources=${allSources ? 1 : 0}`);
	}

	static unconfigured(): Promise<AppTenant[]> {
		return Api.GET(`tenants/unconfigured`).then((response) => {
			return response as AppTenant[];
		});
	}
}
